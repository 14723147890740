import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../../../../redux/orders/orderSlice";
import BtcWebSocket from "./btcWebSocket";
import { Filter } from "@material-ui/icons";
import EthWebSocket from "./ethWebSocket";
import XrpWebSocket from "./xrpWebSocket";
import DogeCoinWebsocket from "./dogeCoingWebSocket";
import AdaWebSocket from "./adaWebSocket";
import DashWebSocket from "./dashWebSocket";
import BnbWebSocket from "./bnbWebSocket";
import EosWebSocket from "./eosWebSocket";
import IotaWebSocket from "./iotaWebSocket";
import LtcWebSocket from "./ltcWebSocket";
import MaticWebSocket from "./maticWebSocket";
import NeoWebSocket from "./neoWebSocket";
import SolWebSocket from "./solWebSocket";
import TrxWebSocket from "./trxWebSocket";
import XlmWebSocket from "./xlmWebSocket";
import { Button, TextField } from "@mui/material";
import { orderServices } from "../../../../../services/orderServices";
import useWebSocket from "react-use-websocket";
import { getCryptoBidandAskPrices } from "../../../../../services/cryptoWebsocketPricesServices";
import { getOpennedOrderWebsocket } from "../../../../../redux/cryptoWebsocket/websocketSlice";
import { userServices } from "../../../../../services/userServices";
import { getUser, getUserBalance } from "../../../../../redux/users/userSlice";
import useInterval from "use-interval";
import TriggerAutomaticCloseOrder from "./TriggerAutomaticCloseOrder";
import ShibaWebSocket from "./shibaWebSocket";
import { getAllRegisteredUsers } from "../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // hide last border
  "td,th": {
    border: 0,
  },
}));

export default function OpenedOrdersTable({
  user,
  setOpenModal,
  fetchUsersPayload,
}) {
  const dispatch = useDispatch();

  const userRedux = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const loggedInUserRole = userRedux?.role;
  const loggedInUserName =
    userRedux?.role !== "admin" &&
    userRedux?.role !== "head_of_conversion" &&
    userRedux?.role !== "crm_manager"
      ? userRedux?.firstName + " " + userRedux?.lastName
      : "";
  const orders = useSelector((state) => state.orders.orders);
  const [currentBtcPrice, setCurrentBtcPrice] = React.useState("");
  const [currentEthPrice, setCurrentEthPrice] = React.useState("");
  const [currentXrpPrice, setCurrentXrpPrice] = React.useState("");
  const [currentDogePrice, setCurrentDogePrice] = React.useState("");
  const [currentAdaPrice, setCurrentAdaPrice] = React.useState("");
  const [currentDashPrice, setCurrentDashPrice] = React.useState("");
  const [currentBnbPrice, setCurrentBnbPrice] = React.useState("");
  const [currentEosPrice, setCurrentEosPrice] = React.useState("");
  const [currentIotaPrice, setCurrentIotaPrice] = React.useState("");
  const [currentLtcPrice, setCurrentLtcPrice] = React.useState("");
  const [currentMaticPrice, setCurrentMaticPrice] = React.useState("");
  const [currentNeoPrice, setCurrentNeoPrice] = React.useState("");
  const [currentSolPrice, setCurrentSolPrice] = React.useState("");
  const [currentTrxPrice, setCurrentTrxPrice] = React.useState("");
  const [currentShibaPrice, setCurrentShibaPrice] = React.useState("");
  const [currentXlmPrice, setCurrentXlmPrice] = React.useState("");
  const [closeAutomaticaly, setCloseAutomaticaly] = React.useState(false);
  const [investmentAmount, setInvestmentAmount] = React.useState("");
  const [leverage, setLeverage] = React.useState("");
  const [buyOrSellManual, setbuyOrSellManual] = React.useState("");
  const [orderForEdit, setOrderForEdit] = React.useState("");
  const chosenOrder = orders?.filter((order) => order?.id === orderForEdit);

  // const [openAlert, setOpenAlert] = React.useState(false);
  let openAlert = false;
  // React.useEffect(() => {
  //   if (!!currentOpenedOrder) {
  //     dispatch(getOpennedOrderWebsocket(currentOpenedOrder));
  //   } else {
  //     return;
  //   }
  // }, []);
  const returnCurrentAssetPriceByRow = (order) => {
    let orderAsset = order.assetType.toLowerCase().replace("usdt", "");
    switch (orderAsset) {
      case "btc":
        return currentBtcPrice;
      case "eth":
        return currentEthPrice;
      case "xrp":
        return currentXrpPrice;
      case "doge":
        return currentDogePrice;
      case "ada":
        return currentAdaPrice;
      case "dash":
        return currentDashPrice;
      case "bnb":
        return currentBnbPrice;
      case "eos":
        return currentEosPrice;
      case "iota":
        return currentIotaPrice;
      case "ltc":
        return currentLtcPrice;
      case "pol":
        return currentMaticPrice;
      case "neo":
        return currentNeoPrice;
      case "sol":
        return currentSolPrice;
      case "trx":
        return currentTrxPrice;
      case "shib":
        return currentShibaPrice;
      default:
        return currentXlmPrice;
    }
  };
  function perIncrease(currentPrice, openedPrice, buyOrSell) {
    let increasedNumbBuy = Number(currentPrice) - Number(openedPrice);
    let increasedNumbSell = Number(openedPrice) - Number(currentPrice);

    return buyOrSell.toLowerCase() === "buy"
      ? 100 * (increasedNumbBuy / Number(currentPrice))
      : 100 * (increasedNumbSell / Number(currentPrice));
  }
  function investmentAmountPer(investmentAmount, percentage) {
    return (Number(investmentAmount) / 100) * Number(percentage);
  }
  setInterval(() => {
    //!isstoplossandtakeprofit was in the if
    if (openAlert) {
      setCloseAutomaticaly(true);
      openAlert = false;
    } else {
      return;
    }
  }, 3000);
  const handleInvestmentAmountChange = (e) => {
    const value = e.target.value;
    setInvestmentAmount(value);
  };

  const handleLeverageChange = (e) => {
    const value = e.target.value;
    setLeverage(value);
  };

  const handleBuyOrSellChange = (e) => {
    const value = e.target.value;
    setbuyOrSellManual(value);
  };
  function isApproximatelyEqual(number1, number2) {
    return Math.abs(number1 - number2) <= 1500;
  }
  const handleUpdateOrderCustomData = async () => {
    await orderServices
      .updateOrder(orderForEdit, {
        leverage: !!leverage
          ? Number(leverage)
          : Number(chosenOrder[0]?.leverage),
        investmentAmount: !!investmentAmount
          ? investmentAmount
          : chosenOrder[0]?.investmentAmount,
        buyOrSell: !!buyOrSellManual
          ? buyOrSellManual
          : chosenOrder[0]?.buyOrSell,
      })
      .then(() => {
        dispatch(getUserOrders(user?.id));
        setOpenModal(false);
      });
  };
  const handleCloseOrder = (
    orderId,
    profit,
    currentPrice,
    liveInvestmentAmount,
    orderLeverage,
    orderBuyOrSell
  ) => {
    orderServices
      .updateOrder(orderId, {
        closedAt: +new Date(),
        closedPrice: currentPrice,
        profit: profit,
        leverage: !!leverage ? Number(leverage) : Number(orderLeverage),
        investmentAmount: !!investmentAmount
          ? investmentAmount
          : liveInvestmentAmount,
        buyOrSell: !!buyOrSellManual ? buyOrSellManual : orderBuyOrSell,
      })
      .then(() => {
        dispatch(getUserOrders(user?.id));
      });
    userServices
      .changeUserBallance(user.id, {
        ballance: (
          Number(user.ballance) +
          Number(profit) +
          Number(liveInvestmentAmount)
        ).toString(),
      })
      .then(() => {
        if (window.location.href.includes("trade")) {
          dispatch(getUser(user.id));
        } else {
          dispatch(getUserBalance(user.id));

          dispatch(getAllRegisteredUsers(fetchUsersPayload));
          setOpenModal(false);
        }
      });
  };

  return (
    <div className="orders-table-root-container">
      {(userRedux?.role === "admin" || userRedux?.role === "retention") &&
        orderForEdit && (
          <div className="orders-table-root-admin-methods">
            <div className="single-orders-table-option-container">
              <TextField
                className="edit-user-textField"
                label="Investment amount"
                onChange={(e) => handleInvestmentAmountChange(e)}
                value={investmentAmount}
                variant="outlined"
                style={{ margin: "12px 2px", width: "150px" }}
                name="investmentAmount"
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            </div>
            <div className="single-orders-table-option-container">
              <TextField
                className="edit-user-textField"
                label="Leverage"
                onChange={(e) => handleLeverageChange(e)}
                value={leverage}
                variant="outlined"
                style={{ margin: "12px 2px", width: "150px" }}
                name="leverage"
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            </div>
            <div className="single-orders-table-option-container">
              <FormControl style={{ minWidth: "200px" }}>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Buy or Sell
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",

                    margin: "12px 2px",
                    borderRadius: "2px",
                    backgroundColor: "#1F2A40",
                  }}
                  label="Buy or Sell"
                  name="buyOrSell"
                  onChange={(e) => handleBuyOrSellChange(e)}
                >
                  {["Buy", "Sell"].map((v) => (
                    <MenuItem value={v?.toLowerCase()}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              color="warning"
              style={{ color: "white", fontWeight: "bold" }}
              onClick={handleUpdateOrderCustomData}
              disabled={!investmentAmount && !leverage && !buyOrSellManual}
            >
              Update order
            </Button>
          </div>
        )}
      <TableContainer component={Paper}>
        <Table
          className="openned-orders-table"
          sx={{
            minWidth: window.innerWidth <= 667 ? 1000 : 700,
            overflowY: "auto",
            maxHeight: 500,
          }}
          aria-label="customized table"
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
              >
                OPEN TIME
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                ASSET
              </StyledTableCell>
              {/* <StyledTableCell                 style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
 align="center">QUANTITY</StyledTableCell> */}
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                INVESTMENT AMOUNT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                ENTRY
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                ASSET PRICE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                LEVERAGE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                STOP LOSS
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                TAKE PROFIT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                PROFIT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                CLOSE ORDER
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {orders
              ?.filter((order) => !order.closedAt)
              .map((order, index) => {
                let diffBetweenTwoNumbersInPercent;

                diffBetweenTwoNumbersInPercent = perIncrease(
                  returnCurrentAssetPriceByRow(order),
                  order.openPrice,
                  !!buyOrSellManual && orderForEdit === order?.id
                    ? buyOrSellManual
                    : order.buyOrSell
                );
                const investmentAmountPercentage = investmentAmountPer(
                  !!investmentAmount && orderForEdit === order?.id
                    ? investmentAmount
                    : order?.investmentAmount,
                  diffBetweenTwoNumbersInPercent
                );
                const profitBeforeCheck =
                  investmentAmountPercentage *
                  Number(
                    !!leverage && orderForEdit === order?.id
                      ? leverage
                      : order.leverage
                  ).toFixed(2);
                let profit = isNaN(profitBeforeCheck)
                  ? "---"
                  : profitBeforeCheck.toFixed(2);

                window.localStorage.setItem(`profit${order?.id}`, profit);
                if (
                  profit !== "---" &&
                  isFinite(profit) &&
                  (profit >= Number(order?.takeProfit) ||
                    profit <= Number(-order?.stopLoss))
                ) {
                  openAlert = true;
                }
                return (
                  <StyledTableRow
                    key={order?.id}
                    style={{
                      background:
                        orderForEdit === order?.id
                          ? "rgb(22 97 77)"
                          : "#07232E",
                      borderBottom: "1px solid grey",
                      cursor:
                        (userRedux?.role === "admin" ||
                          userRedux?.role === "retention") &&
                        "pointer",
                    }}
                    onClick={() => {
                      if (
                        userRedux?.role === "admin" ||
                        userRedux?.role === "retention"
                      ) {
                        setOrderForEdit(order.id);
                      }
                    }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        color: "rgb(183 189 192)",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                    >
                      {new Date(+order?.createdAt).toUTCString()}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {order?.assetType}
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {!!investmentAmount && orderForEdit === order?.id
                        ? investmentAmount
                        : order?.investmentAmount}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {order.type === "limit"
                        ? "---"
                        : `${
                            userRedux?.currencySymbol
                          }${order?.openPrice.replace(",", "")}`}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {!order.assetType ||
                      order.assetType.toLowerCase().includes("btc") ? (
                        <BtcWebSocket setCurrentPrice={setCurrentBtcPrice} />
                      ) : order.assetType.toLowerCase().includes("eth") ? (
                        <EthWebSocket setCurrentPrice={setCurrentEthPrice} />
                      ) : order.assetType.toLowerCase().includes("xrp") ? (
                        <XrpWebSocket setCurrentPrice={setCurrentXrpPrice} />
                      ) : order.assetType.toLowerCase().includes("doge") ? (
                        <DogeCoinWebsocket
                          setCurrentPrice={setCurrentDogePrice}
                        />
                      ) : order.assetType.toLowerCase().includes("ada") ? (
                        <AdaWebSocket setCurrentPrice={setCurrentAdaPrice} />
                      ) : order.assetType.toLowerCase().includes("dash") ? (
                        <DashWebSocket setCurrentPrice={setCurrentDashPrice} />
                      ) : order.assetType.toLowerCase().includes("bnb") ? (
                        <BnbWebSocket setCurrentPrice={setCurrentBnbPrice} />
                      ) : order.assetType.toLowerCase().includes("eos") ? (
                        <EosWebSocket setCurrentPrice={setCurrentEosPrice} />
                      ) : order.assetType.toLowerCase().includes("iota") ? (
                        <IotaWebSocket setCurrentPrice={setCurrentIotaPrice} />
                      ) : order.assetType.toLowerCase().includes("ltc") ? (
                        <LtcWebSocket setCurrentPrice={setCurrentLtcPrice} />
                      ) : order.assetType.toLowerCase().includes("pol") ? (
                        <MaticWebSocket
                          setCurrentPrice={setCurrentMaticPrice}
                        />
                      ) : order.assetType.toLowerCase().includes("neo") ? (
                        <NeoWebSocket setCurrentPrice={setCurrentNeoPrice} />
                      ) : order.assetType.toLowerCase().includes("sol") ? (
                        <SolWebSocket setCurrentPrice={setCurrentSolPrice} />
                      ) : order.assetType.toLowerCase().includes("trx") ? (
                        <TrxWebSocket setCurrentPrice={setCurrentTrxPrice} />
                      ) : order.assetType.toLowerCase().includes("shib") ? (
                        <ShibaWebSocket
                          setCurrentPrice={setCurrentShibaPrice}
                        />
                      ) : (
                        <XlmWebSocket setCurrentPrice={setCurrentXlmPrice} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {!!leverage && orderForEdit === order?.id
                        ? leverage
                        : order?.leverage}
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {!order?.stopLoss ? "---" : order?.stopLoss}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {!order?.takeProfit ? "---" : order?.takeProfit}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: profit.toString().includes("-")
                          ? "red"
                          : profit === "---"
                          ? "white"
                          : "#18d818",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      {profit}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: window.innerWidth <= 667 && "0.7rem",
                      }}
                      align="center"
                    >
                      <TriggerAutomaticCloseOrder
                        leverage={order.leverage}
                        stopLoss={order.stopLoss}
                        takeProfit={order.takeProfit}
                        closeAutomaticaly={closeAutomaticaly}
                        setCloseAutomaticaly={setCloseAutomaticaly}
                        profit={profit}
                        order={order}
                        currentPrice={returnCurrentAssetPriceByRow(order)}
                        handleCloseOrder={handleCloseOrder}
                      ></TriggerAutomaticCloseOrder>
                      {profit === "---" ? null : (
                        <Button
                          onClick={() =>
                            handleCloseOrder(
                              order.id,
                              profit,
                              returnCurrentAssetPriceByRow(order),
                              !!investmentAmount
                                ? investmentAmount
                                : order.investmentAmount,
                              !!leverage ? leverage : order?.leverage,
                              !!buyOrSellManual
                                ? buyOrSellManual
                                : order?.buyOrSell
                            )
                          }
                          style={{ color: "red" }}
                        >
                          Close Order
                        </Button>
                        // Review fetch balance redirect
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {openAlert === true ? alert("Please refresh to take your profit!") : null} */}
    </div>
  );
}
